import React from "react"

import { withAccountDetails } from "./withAccountDetails"
import { AccountDetailsForm } from "./Form/AccountDetailsForm"
import { AccountHeader } from "../Header/AccountHeader"
import { H3 } from "../../Styled/Text"
import { StyledContainer } from "../../Styled/Container"
import { Page } from "./styledAccountDetails"

export const AccountDetails = withAccountDetails(
  ({ location, page, register, template }): JSX.Element => (
    <>
      <AccountHeader location={location} page={page} template={template} />
      <Page>
        <StyledContainer width={`md`}>
          <H3 align={`center`} withSpacing>
            {page?.title}
          </H3>
          <AccountDetailsForm page={page} register={register} template={template} />
        </StyledContainer>
      </Page>
    </>
  )
)
