import React from "react"

import { withAccountDetailsForm } from "./withAccountDetailsForm"
import { FormInput } from "../../../Form/Input/FormInput"
import { FormSelect } from "../../../Form/Select/FormSelect"
import { FormTel } from "../../../Form/Tel/FormTel"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { Form, Button, Link } from "./styledAccountDetailsForm"

export const AccountDetailsForm = withAccountDetailsForm(
  ({
    changed,
    customer,
    customerInformation,
    errors,
    handleChange,
    handleInformationChange,
    handleReset,
    handleSubmit,
    loading,
    locales,
    options,
  }): JSX.Element => (
    <>
      <Form onSubmit={handleSubmit}>
        <FormInput
          name={`firstName`}
          type={`text`}
          full
          onChange={handleChange}
          placeholder={locales?.additionalFirstName}
          required
          value={customer?.firstName || ""}
          withSpacing={`xs`}
        />
        <FormInput
          name={`lastName`}
          type={`text`}
          full
          onChange={handleChange}
          placeholder={locales?.additionalLastName}
          required
          value={customer?.lastName || ""}
          withSpacing={`xs`}
        />
        <FormInput
          name={`email`}
          type={`email`}
          full
          onChange={handleChange}
          placeholder={locales?.additionalEmail}
          required
          value={customer?.email || ""}
          withSpacing={`xs`}
        />
        <FormTel
          name={`phone`}
          full
          onChange={handleChange}
          placeholder={locales?.additionalPhone}
          value={customer?.phone || ""}
          withSpacing={`xs`}
        />
        {/* <StyledRow gutter withSpacing={`xs`}>
          <StyledColumn gutter width={`1/3`}>
            <FormSelect
              name={`day`}
              full
              onChange={handleInformationChange}
              placeholder={locales?.additionalBirthdayDay}
              options={options?.day}
              required={customerInformation?.day}
              value={customerInformation?.day}
            />
          </StyledColumn>
          <StyledColumn gutter width={`1/3`}>
            <FormSelect
              name={`month`}
              full
              onChange={handleInformationChange}
              placeholder={locales?.additionalBirthdayMonth}
              options={options?.month}
              required={customerInformation?.month}
              value={customerInformation?.month}
            />
          </StyledColumn>
          <StyledColumn gutter width={`1/3`}>
            <FormSelect
              name={`year`}
              full
              onChange={handleInformationChange}
              placeholder={locales?.additionalBirthdayYear}
              options={options?.year}
              required={customerInformation?.year}
              value={customerInformation?.year}
            />
          </StyledColumn>
        </StyledRow> */}
        <FormSelect
          name={`reference`}
          errors={errors}
          full
          onChange={handleInformationChange}
          placeholder={locales?.additionalReference}
          options={options?.references}
          required={customerInformation?.reference}
          value={customerInformation?.reference}
          withSpacing={`lg`}
        />
        <Button
          type={`submit`}
          colour={`purple`}
          size={`primary`}
          disabled={loading || !changed}
          title={locales?.additionalSubmit}
          withSpacing={`xs`}
        >
          {changed ? locales?.additionalSubmit : locales?.additionalSaved}
        </Button>
        <Link
          type={`button`}
          colour={`grey-darker`}
          disabled={loading || !changed}
          onClick={handleReset}
          size={`large`}
          title={locales?.additionalReset}
        >
          {locales?.additionalReset}
        </Link>
      </Form>
    </>
  )
)
