import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCustomerDetails } from "../../../../hooks/useCustomer"
import { useFunctions } from "../../../../hooks/useFunctions"

export const withAccountDetailsForm = Component =>
  memo(({ name = "AccountDetailsForm", page, register, template }: any) => {
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const { customer, setCustomer, changed, resetForm, updateCustomer, loading, saving, errors } = useCustomerDetails()
    const { callFunction, loading: tagsLoading, errors: tagsErrors } = useFunctions()
    const [customerInformation, setCustomerInformation] = useState(null)
    const [fetchedCustomerInformation, setFetchedCustomerInformation] = useState(null)

    const now = new Date().getFullYear()
    const birthday = useMemo(
      () => customer?.tags?.find((tag: string) => tag?.startsWith(keys?.birthday))?.replace(keys?.birthday, ``) || "",
      [customer, keys]
    )
    const reference = useMemo(
      () => customer?.tags?.find((tag: string) => tag?.startsWith(keys?.reference))?.replace(keys?.reference, ``) || "",
      [customer, keys]
    )

    const locales = useMemo(
      () => ({
        ...register,
        ...page,
      }),
      [page, register]
    )
    const options = useMemo(
      () => ({
        day: Array.from(Array(31).keys()).map((_, i) => ({
          value: i + 1,
        })),
        month: Array.from(Array(12).keys()).map((_, i) => ({
          value: i + 1,
        })),
        year: Array.from(Array(100).keys())
          .map((_, i) => ({
            value: i + (now - 99),
          }))
          .reverse(),
        references: page?.references.map((reference: string) => ({
          value: reference,
        })),
      }),
      [now, page]
    )

    const formatBirthday = useCallback(
      customerInformation =>
        customerInformation?.day && customerInformation?.month && customerInformation?.year
          ? `${customerInformation?.day}-${customerInformation?.month}-${customerInformation?.year}`
          : "",
      []
    )

    const formatTags = useCallback(
      customerInformation => {
        const tags = [...(customer?.tags?.filter((tag: string) => !tag?.startsWith(keys?.birthday) && !tag?.startsWith(keys?.reference)) || [])]

        if (formatBirthday(customerInformation)) {
          tags.push(`${keys?.birthday}${formatBirthday(customerInformation)}`)
        }
        if (customerInformation?.reference) {
          tags.push(`${keys?.reference}${customerInformation?.reference}`)
        }

        return tags
      },
      [customer, formatBirthday, keys]
    )

    const tagsChanged =
      formatBirthday(fetchedCustomerInformation) !== formatBirthday(customerInformation) ||
      fetchedCustomerInformation?.reference != customerInformation?.reference

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) =>
        setCustomer(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        })),
      [setCustomer]
    )

    const handleInformationChange = useCallback(
      ({ target: { type, name, value, checked } }) =>
        setCustomerInformation(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        })),
      [setCustomerInformation]
    )

    const handleReset = useCallback(() => {
      resetForm()
      setCustomerInformation({
        day: birthday?.split(`-`)?.[0] || "",
        month: birthday?.split(`-`)?.[1] || "",
        year: birthday?.split(`-`)?.[2] || "",
        reference: reference || "",
      })
    }, [birthday, reference, resetForm, setCustomerInformation])

    const mailchimpSubmit = useCallback(() => {
      if (
        template?.mailchimpFormUrl &&
        template?.mailchimpListId &&
        template?.mailchimpUserId &&
        template?.mailchimpEmailMerge &&
        template?.mailchimpBirthdayMerge
      ) {
        if (customerInformation?.day && customerInformation?.month && customer?.email) {
          const keys = `u=${template?.mailchimpUserId}&id=${template?.mailchimpListId}`
          const data = `&${template?.mailchimpEmailMerge}=${customer?.email}
          ${customer?.firstName && template?.mailchimpFirstNameMerge ? `&${template?.mailchimpFirstNameMerge}=${customer?.firstName}` : ""}
          ${customer?.lastName && template?.mailchimpLastNameMerge ? `&${template?.mailchimpLastNameMerge}=${customer?.lastName}` : ""}
          &${template?.mailchimpBirthdayMerge}[day]=${customerInformation?.day}&${template?.mailchimpBirthdayMerge}[month]=${
            customerInformation?.month
          }
          `

          fetch(template.mailchimpFormUrl, {
            method: "post",
            mode: `no-cors`,
            body: `${keys}${data}`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
        }
      }
    }, [customerInformation, customer, template])

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()
        await updateCustomer(customer)

        if (tagsChanged) {
          mailchimpSubmit()
          await callFunction("customer-tags", { id: customer.id, tags: formatTags(customerInformation) })
          setFetchedCustomerInformation(customerInformation)
        }
      },
      [customer, customerInformation, callFunction, formatTags, tagsChanged, updateCustomer, mailchimpSubmit]
    )

    useEffect(() => {
      if ((birthday || reference) && !customerInformation) {
        setCustomerInformation({
          day: birthday?.split(`-`)?.[0] || "",
          month: birthday?.split(`-`)?.[1] || "",
          year: birthday?.split(`-`)?.[2] || "",
          reference: reference || "",
        })
        setFetchedCustomerInformation({
          day: birthday?.split(`-`)?.[0] || "",
          month: birthday?.split(`-`)?.[1] || "",
          year: birthday?.split(`-`)?.[2] || "",
          reference: reference || "",
        })
      }
    }, [birthday, customer, customerInformation, reference])

    Component.displayName = name
    return useMemo(
      () =>
        !loading ? (
          <Component
            changed={changed || tagsChanged}
            customer={customer}
            customerInformation={customerInformation}
            errors={errors || tagsErrors}
            handleChange={handleChange}
            handleInformationChange={handleInformationChange}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            loading={saving || tagsLoading}
            locales={locales}
            options={options}
          />
        ) : null,
      [
        changed,
        tagsChanged,
        customer,
        customerInformation,
        errors,
        tagsErrors,
        handleChange,
        handleInformationChange,
        handleReset,
        handleSubmit,
        loading,
        saving,
        tagsLoading,
        locales,
        options,
      ]
    )
  })
