import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../hoc/withAuthentication"
import { AccountDetails as Page } from "../components/Account/Details/AccountDetails"

export const query = graphql`
  query {
    page: sanityPageAccountDashboard {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      references
      additionalPhone
      additionalBirthday
      additionalBirthdayDay
      additionalBirthdayMonth
      additionalBirthdayYear
      additionalReference
      additionalSubmit
      additionalSaved
      additionalReset
    }
    register: sanityPageAccountRegister {
      additionalFirstName
      additionalLastName
      additionalEmail
      additionalPassword
    }
    template: sanityTemplateAccount {
      bannerTitle
      bannerText
      bannerBackground
      bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      mailchimpFormUrl
      mailchimpUserId
      mailchimpListId
      mailchimpEmailMerge
      mailchimpFirstNameMerge
      mailchimpLastNameMerge
      mailchimpBirthdayMerge
    }
  }
`

const Component = withAuthentication(({ data, ...props }) => <Page {...props} {...data} />)

export default Component
